
import GoogleIcon from "../../assets/img/icons/google-oauth2.png"

export const AUTH_CONFIG = {
	domain: process.env.REACT_APP_AUTH0_DOMAIN || 'auth.reecall.co',
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'TODLg9AwKPMAT3Ia2FeT9nW1kLPsZ3zq',
	container: 'auth0-login-container'
};

var queryParams = new URLSearchParams(window.location.search);

const LockLanguageDictionnary = {
    'fr': {
        title: '',
    },
    'en': {
        title: '',
    }
}

const LockDefaultConfig = (locale) => {
	let langOptions = LockLanguageDictionnary?.[locale];
	if(!langOptions){
		langOptions = LockLanguageDictionnary["en"];
	}
	let baseOptions = {
		configurationBaseUrl: "https://"+AUTH_CONFIG.domain,
		auth: {
			redirect: false,
			redirectUrl: window.location.origin + '/login?goto=' + escape(window.location.pathname),
			responseType: 'token id_token',
			autoParseHash: true,
			params: {
				scope: 'openid profile email'
			}
		},
		avatar: null,
		closable: false,
		container: AUTH_CONFIG.container,
		language: locale || 'en',
		allowSignUp: false,
		prefill: queryParams.has('email') ? { email: queryParams.get('email') } : {},
		languageDictionary: {
			...langOptions
		},
		rememberLastLogin: true,
		theme: {
			primaryColor: '#465CFF',
			logo: '//assets.reecall.co/logos/svg/logo_reecall_2020.svg',
			authButtons: {
				"google-oauth2": {
				  primaryColor: "#ffffff",
				  foregroundColor: "#4f43df",
				  icon: GoogleIcon
				}
			}
		},
		usernameStyle: 'email'
	}

	return {
		...baseOptions
	}
}

export { LockDefaultConfig }
