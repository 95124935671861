import React, {useState} from "react";
import {RcMenu} from "@reecall/components_library/build/index"

const MenuTest = [
    {
        type: "group",
        label: "Dashboards",
        collapse: true,
        id: "main-dashboards",
        childrens: [
            {
                type: "item",
                label: "Companies",
                icon: "buildings",
                id: "dashboards-companies"
            },
            {
                type: "item",
                label: "Metrics",
                icon: "chart-simple",
                id: "dashboards-metrics"
            }
        ]
    },
    {
        type: "group",
        label: "Bookmarks",
        icon: "fa-star",
        id: "main-bookmarks"
    },
    // {
    //     type: "group",
    //     label: "Ma petite Boutique",
    //     icon: "fa-building",
    //     id: "main-company",
    //     collapse: true,
    //     sortable: true,
    //     childrens: [
    //         {
    //             type: "item",
    //             label: "Analytics",
    //             icon: "chart-simple",
    //             id: "company-analytics"
    //         },
    //         {
    //             type: "item",
    //             label: "Conversations",
    //             icon: "messages",
    //             id: "company-conversations"
    //         },
    //         {
    //             type: "item",
    //             label: "Tickets",
    //             icon: "ballot-check",
    //             id: "company-tickets"
    //         },
    //         {
    //             type: "item",
    //             label: "Contacts",
    //             icon: "users",
    //             id: "company-contacts"
    //         },
    //         {
    //             type: "item",
    //             label: "Processes",
    //             icon: "wand-magic-sparkles",
    //             id: "company-processes"
    //         },
    //         {
    //             type: "item",
    //             label: "Events",
    //             icon: "bolt",
    //             id: "company-events"
    //         },
    //         {
    //             type: "item",
    //             label: "References",
    //             icon: "books",
    //             id: "company-references"
    //         },
    //         {
    //             type: "item",
    //             label: "Exports",
    //             icon: "download",
    //             id: "company-exports"
    //         },
    //         {
    //             type: "item",
    //             label: "Channels",
    //             icon: "grid-horizontal",
    //             id: "company-channels"
    //         },
    //         {
    //             type: "item",
    //             label: "Agents",
    //             icon: "message-bot",
    //             id: "company-agents"
    //         },
    //         {
    //             type: "item",
    //             label: "Members",
    //             icon: "user",
    //             id: "company-members"
    //         },
    //         {
    //             type: "item",
    //             label: "FAQ",
    //             icon: "circle-question",
    //             id: "company-faq"
    //         },
    //         {
    //             type: "item",
    //             label: "Settings",
    //             icon: "gears",
    //             id: "company-settings"
    //         }
    //     ]
    // }
]

const Sidebar = ({className}) => {
    const [currentMenu] = useState(MenuTest);
    return <div className="sidebar">
        <RcMenu
            className={className}
            // edition={true}
            // onUpdate={(newMenu) => {
            //     setCurrentMenu(newMenu);
            // }}
            config={currentMenu}
        />
    </div>
}

export default Sidebar
