import React, { useRef, useEffect } from 'react';
import {AUTH_CONFIG} from '../../../services/Auth/config';
import Lock from '../../../services/Auth/Lock';
import {authActions} from '../../../services/Auth/AuthSlice';
import './auth.scss';

import { useDispatch } from "react-redux"

const Login = () => {
    const loginRef = useRef();
    const dispatch = useDispatch();

    useEffect(() => {
        if(loginRef?.current){
            Lock.show();
            Lock.on("authenticated", function(authResult) {
                Lock.getUserInfo(authResult.accessToken, function(error, profile) {
                    return dispatch(authActions.login({
                        error: error,
                        profile: profile,
                        authResult: authResult,
                    }))
                });
            });
        }
    }, [dispatch, loginRef]);

    return <div id="login-container">
        <div 
            id={AUTH_CONFIG.container}
            style={{ width: '100%', height: 'fit-content' }}
            ref={loginRef}
        />
    </div>
}

export default Login;
