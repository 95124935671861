import { Outlet, Navigate } from "react-router-dom";

const ProtectedRoute = ({
    isAuthenticated,
    children
}) => {

    if(!isAuthenticated){
        return <Navigate to={`login?goto=${escape(window.location.pathname)}`} replace />
    }
  
    return children ? children : <Outlet />;
};

export default ProtectedRoute;