import ReecallSdk from "@reecall/reecall_sdk";
import { API_BASE, SEARCH_URL } from "../../config/constants";

const rcSDK = new ReecallSdk(localStorage.getItem('id_token') || 'notoken', {
    withStats: true
});

if (API_BASE) {
    rcSDK.API_BASE = `${API_BASE}/api`;
}

if (SEARCH_URL) {
    rcSDK.Search.API_BASE = `${SEARCH_URL}/api`;
}

export default rcSDK;