import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Nav, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../services/Auth/AuthSlice";
import { useTheme } from "styled-components"

const NavbarUser = ({className}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const auth = useSelector(state => state.auth);
    const user = useSelector(state => state.user);

    return <Nav className={className}>
        <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link} className="active me-0">
                { user.loading && <Spinner variant="light" size="sm" />}
                {
                    !user.loading && user?.profile && <div className="d-inline-flex align-items-center">
                        <span className="text-bold-600 mb-0 me-50">
                            {user.profile.firstName} {user.profile.lastName}
                        </span>
                    </div>
                }
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" variant={theme.name}>
                <Dropdown.Item
                    tag="a"
                    href="/login"
                    onClick={e => {
                        e.preventDefault()
                        if (auth.isAuthenticated) {
                            dispatch(authActions.logout());
                        }
                    }}
                >
                    <FontAwesomeIcon icon={["far", "right-from-bracket"]} className="me-50"/>
                    {"Log out"}
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </Nav>
}

export default NavbarUser;