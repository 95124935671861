import Auth0Lock from 'auth0-lock';
import { AUTH_CONFIG, LockDefaultConfig } from './config';

var queryParams = new URLSearchParams(window.location.search);
let locale = queryParams.get('lang') || localStorage.getItem('reecall-webapp-locale') || navigator.language.slice(0, 2) || 'en';
const Lock = new Auth0Lock(
    AUTH_CONFIG.clientId,
    AUTH_CONFIG.domain,
    {
		...LockDefaultConfig(locale)
	}
);

export default Lock;