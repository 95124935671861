import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ButtonToolbar, ButtonGroup, DropdownButton, Dropdown, Row, Col, ListGroup, Tabs, Tab, Nav } from "react-bootstrap"

import { Button } from "@reecall/components_library/build"
//import { Table as RcTable } from "@reecall/components_library/build"
//import { IntlProvider } from "react-intl";

const PageTest = ({children}) => {

    /*const initialColumns = useMemo(
        () => [
            {
                label: 'First Name',
                accessor: 'firstName',
                icon: "user",
                type: "text",
                sticky: "left",
            },
            {
                label: 'Last Name',
                accessor: 'lastName',
                type: "text",
                format: 'uppercase',
            }
        ],
        []
    )*/

    //const [columns, setColumns]             = useState(initialColumns);
    //const [datas, setDatas]                 = useState([]);

    return <>

        {/* <RcTable
            columns={columns}
            data={datas}
            filters={[]}
            sorts={[]}
        /> */}


        <Row className="mt-2 mb-2">
            <Col>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                        <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                            <Nav.Link eventKey="first">Tab 1</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                            <Nav.Link eventKey="second">Tab 2</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        </Col>
                        <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                {"first"}
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                {"second"}
                            </Tab.Pane>
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Col>
        </Row>

        <Row className="mb-2">
            <Col>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-2"
                >
                    <Tab eventKey="home" title="Home">
                        {"home"}
                    </Tab>
                    <Tab eventKey="profile" title="Profile">
                        {"profil"}
                    </Tab>
                    <Tab eventKey="contact" title="Contact" disabled>
                        {"contact"}
                    </Tab>
                </Tabs>
            </Col>
        </Row>


        <Row className="mb-2">
            <Col>
                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example-2"
                    className="mb-2"
                    fill
                >
                    <Tab eventKey="home" title="Home">
                        {"home"}
                    </Tab>
                    <Tab eventKey="profile" title="Profile">
                        {"profil"}
                    </Tab>
                    <Tab eventKey="contact" title="Contact" disabled>
                        {"contact"}
                    </Tab>
                </Tabs>
            </Col>
        </Row>

        <Row className="mb-2">
            <Col>
                {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger'].map(
                    (variant) => (
                    <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={variant.toLowerCase()}
                        title={variant}
                        className="me-1"
                        menuVariant="dark"
                    >
                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                        <Dropdown.Item eventKey="3" active>
                        Active Item
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                    </DropdownButton>
                    ),
                )}
            </Col>
        </Row>

        <Row className="mb-2">
            <Col>
                {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger'].map(
                    (variant) => (
                    <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={`flat-${variant.toLowerCase()}`}
                        title={variant}
                        className="me-1"
                    >
                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                        <Dropdown.Item eventKey="3" active>
                        Active Item
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                    </DropdownButton>
                    ),
                )}
            </Col>
        </Row>

        <Row className="mb-2">
            <Col>
                {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger'].map(
                    (variant) => (
                    <DropdownButton
                        as={ButtonGroup}
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={`outline-${variant.toLowerCase()}`}
                        title={variant}
                        className="me-1"
                    >
                        <Dropdown.Item eventKey="1">Action</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Another action</Dropdown.Item>
                        <Dropdown.Item eventKey="3" active>
                        Active Item
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="4">Separated link</Dropdown.Item>
                    </DropdownButton>
                    ),
                )}
            </Col>
        </Row>



        <Row className="mb-2">
            <Col>
                {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger', 'Light', 'Dark'].map(
                    (variant) => (
                    <Button
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={`${variant.toLowerCase()}`}
                        title={variant}
                        className="me-1"
                    >
                        {variant}
                    </Button>
                    )
                )}
            </Col>
        </Row>
        <Row className="mb-2">
            <Col>
                {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger', 'Light', 'Dark'].map(
                    (variant) => (
                    <Button
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={`outline-${variant.toLowerCase()}`}
                        title={variant}
                        className="me-1"
                    >
                        {variant}
                    </Button>
                    )
                )}
            </Col>
        </Row>
        <Row className="mb-2">
            <Col>
                {['Primary', 'Secondary', 'Success', 'Info', 'Warning', 'Danger', 'Light', 'Dark'].map(
                    (variant) => (
                    <Button
                        key={variant}
                        id={`dropdown-variants-${variant}`}
                        variant={`flat-${variant.toLowerCase()}`}
                        title={variant}
                        className="me-1"
                    >
                        {variant}
                    </Button>
                    )
                )}
            </Col>
        </Row>

        <Row className="mb-2">
            <Col>
                <ListGroup as="ul">
                    <ListGroup.Item as="li" active>
                        Cras justo odio
                    </ListGroup.Item>
                    <ListGroup.Item as="li">Dapibus ac facilisis in</ListGroup.Item>
                    <ListGroup.Item as="li" disabled>
                        Morbi leo risus
                    </ListGroup.Item>
                    <ListGroup.Item as="li">Porta ac consectetur ac</ListGroup.Item>
                </ListGroup>
            </Col>
        </Row>

        <Row>
            <Col>

                <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup className="me-2" aria-label="First group" size="sm">
                        <Button>Left</Button>
                        <Button>Middle</Button>
                        <Button>Right</Button>
                    </ButtonGroup>
                </ButtonToolbar>
                <br />

                <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup className="me-2" aria-label="First group">
                        <Button>Left</Button>
                        <Button>Middle</Button>
                        <Button>Right</Button>
                    </ButtonGroup>
                </ButtonToolbar>
                <br />

                <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup className="me-2" aria-label="Second group" size="lg">
                        <Button>Left</Button>
                        <Button>Middle</Button>
                        <Button>Right</Button>
                    </ButtonGroup>
                </ButtonToolbar>
                <br />

                <ButtonGroup aria-label="Third group">
                    <Button>Left</Button>
                    <Button>Middle</Button>
                    <Button>Right</Button>
                    <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-nested-dropdown">
                        <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
                        <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
                    </DropdownButton>
                </ButtonGroup>

                <br />
                <br />

                <Button className="btn-icon me-1" size="lg">
                    <FontAwesomeIcon icon={["far", "user"]}/>
                </Button>

                <Button className="btn-icon me-1" size="lg" variant="flat-primary">
                    <FontAwesomeIcon icon={["far", "user"]}/>
                </Button>


                <Button className="btn-icon me-1" size="lg" variant="outline-primary" >
                    <FontAwesomeIcon icon={["far", "user"]}/>
                </Button>

                <Button size="lg" className="me-1">
                    test
                </Button>

                <Button className="btn-icon me-1">
                    <FontAwesomeIcon icon={["far", "user"]}/>
                </Button>

                <Button className="me-1">
                    test
                </Button>

                <Button className="btn-icon me-1" size="sm">
                    <FontAwesomeIcon icon={["far", "user"]}/>
                </Button>

                <Button size="sm" className="me-1">
                    test
                </Button>
            </Col>
        </Row>
    </>
}

export default PageTest;
