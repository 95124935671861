import { useEffect } from "react"; 
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UnauthentifiedRoute = ({
    children
}) => {
    let navigate = useNavigate();
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        let queryParams = new URLSearchParams(window.location.search);
        if (auth.isAuthenticated === true) {
            navigate(
                queryParams.get('goto') || "/",
                { replace: true }
            )
        }
    }, [auth, navigate]);
  
    return children ? children : <Outlet />;
};

export default UnauthentifiedRoute;