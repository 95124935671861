import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavDropdown, Nav, Navbar } from "react-bootstrap"
import { NavLink } from "react-router-dom";
import NavbarUser from "./NavbarUser"
import styled, { useTheme } from "styled-components";

const StyledNavbar = styled(Navbar)`
    padding: .8rem 1rem .8rem calc(2rem - 0.357rem);
    background-color: ${props => props.theme.menuBg};
    box-shadow: 0 4px 24px #22292f1a;

    .navbar-brand{
        width: 234px;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
            width: 116px;
        }
    }

    .nav-item{
        margin-right: 0.5rem;
        &:last-child{
            margin-right: 0;
        }
    }

    .nav-link{
        display: flex;
        align-items: center;
        border-radius: 6px;
        svg{
            height: 1.5rem;
            width: 1.5rem;
        }

        &:hover,
        &:focus{
            background-color: ${props => props.theme.menuHoverBg};
            color: ${props => props.theme.menuHoverFg};
        }
    }
`

const MainNavBar = ({...props}) => {
    const theme = useTheme();

    return <StyledNavbar
        expand="md"
        variant={theme.name}
        {...props}
    >
        {/* <Navbar.Brand as={Link} to="/">
            <img
                src={theme.name === "dark" ? "//assets.reecall.co/logos/svg/logo_reecall_2020_white.svg" : "//assets.reecall.co/logos/svg/logo_reecall_2020.svg"}
                alt="logo-reecall"
            />
        </Navbar.Brand> */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
            id="basic-navbar-nav"
            className="mt-sm-1 mt-md-0"
        >

        <Nav className="w-100">
            <Nav.Item>
                <NavLink to="/" className={"nav-link"}>
                    <FontAwesomeIcon icon={["far", "home"]}/>
                    <span className="d-md-none ms-1">
                      {"Home"}
                    </span>
                </NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/main1" className={"nav-link"}>
                    <FontAwesomeIcon icon={["far", "comment"]}/>
                    <span className="d-md-none ms-1">
                      {"Conversations"}
                    </span>
                </NavLink>
            </Nav.Item>
            <NavDropdown
                title={
                    <>
                        <FontAwesomeIcon icon={["far", "flag"]}/>
                        <span className="d-md-none ms-1">
                            {"Tickets"}
                        </span>
                    </>
                }
                id="basic-nav-dropdown"
                menuVariant={theme.name}
            >
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                    Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                    Separated link
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
        <NavbarUser className="ms-sm-0 ms-md-auto"/>
        </Navbar.Collapse>
    </StyledNavbar>
}

MainNavBar.defaultProps = {
};

MainNavBar.propTypes = {
};

export default MainNavBar;
export { MainNavBar }
