import React from "react";
import { Outlet, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import MainNavbar from "../navbar/MainNavBar";
import Sidebar from "../sidebar/Sidebar";
import styled, {useTheme} from "styled-components"


const StyledSidebarWrapper = styled.div`
    position: relative;
    background-color: ${props => props.theme.menuBg};
    box-shadow: 0 0 15px #22292f1a;

    .brand-icon-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 24px 0 0;

        &:after{
            content: "";
            margin: 24px auto;
            background: ${props => props.theme.menuHoverBg};
            height: 4px;
            border-radius: 2px;
            width: calc(100% - 2rem);
        }

        img{
            width: 116px;
        }
    }
`

const MainLayout = ({children}) => {
    const theme = useTheme();
    return <>
        <div className="content-wrapper">
            <StyledSidebarWrapper>
                <Link to={"/"} className="brand-icon-wrapper">
                    <img
                        src={theme.name === "dark" ? "//assets.reecall.co/logos/svg/logo_reecall_2020_white.svg" : "//assets.reecall.co/logos/svg/logo_reecall_2020.svg"}
                        alt="logo-reecall"
                    />
                </Link>
                <Sidebar />
            </StyledSidebarWrapper>
            <div className="d-flex flex-column w-100">
                <Container className="p-1" fluid>
                    <MainNavbar className="rounded-2"/>
                </Container>
                <div className="content">
                    {children || <Outlet />}
                </div>
            </div>
        </div>
    </>
}

export default MainLayout