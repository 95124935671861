import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from "jwt-decode";
import Lock from '../../services/Auth/Lock';
import rcSDK from '../Api/ApiSdk';

// create slice
const name = 'auth';
const initialState  = createInitialState();
const reducers      = createReducers();

const slice = createSlice({ 
    name,
    initialState,
    reducers
});

// exports
export const authActions = { ...slice.actions };
export const authReducer = slice.reducer;

// implementation
function createInitialState() {
    return {
        isAuthenticated: checkTokenExpiry(),
        profile: getProfile(),
        error: null,
        userRole: "admin"
    }
}

function createReducers() {
    return {
        login,
        logout
    };

    function login(state, action) {
        const {error, profile, authResult} = action.payload;

        if (error) {
            state.error = error;
            state.isAuthenticated = false;
            state.profile = null;
        }

        localStorage.setItem('profile', JSON.stringify(profile))
        localStorage.setItem('id_token', authResult.idToken)
        localStorage.setItem('access_token', authResult.accessToken);

        rcSDK.setToken(authResult.idToken);
        if(rcSDK.Stats){
            rcSDK.Stats.setToken(authResult.idToken);
        }
        if(rcSDK.Search){
            rcSDK.Search.setToken(authResult.idToken);
        }

        state.error = null;
        state.isAuthenticated = true;
        state.profile = profile;
    }

    function logout(state) {
        localStorage.removeItem('profile');
        localStorage.removeItem('id_token');
        localStorage.removeItem('access_token');

        state.error = null;
        state.isAuthenticated = false;
        state.profile = null;
        Lock.logout({
            returnTo: `${window.location.origin}/login`
        });
    }
}

//utils 

function checkTokenExpiry() {
    let jwt = localStorage.getItem('id_token')
    if(jwt) {
        let jwtExp = jwt_decode(jwt).exp;
        let expiryDate = new Date(0);
        expiryDate.setUTCSeconds(jwtExp);

        if(new Date() < expiryDate) {
            return true;
        }
    }
    return false;  
}

function getProfile() {
    return JSON.parse(localStorage.getItem('profile'));
}
