import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const FullLayout = ({children, className = ""}) => {

    return <>
        <Container fluid className={`h-100 d-flex ${className}`}>
            {children || <Outlet />}
        </Container>
    </>
}

export default FullLayout