import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rcSDK from '../services/Api/ApiSdk';

// create slice

const name = 'user';
const initialState = createInitialState();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, extraReducers: (builder) => createExtraReducers(builder) });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        profile: null,
        loading: false,
        error: null
    }
}

function createExtraActions() {
    return {
        getMySelf: getMySelf()
    };    

    function getMySelf() {
        console.log("getMySelf");
        return createAsyncThunk(
            `${name}/getMySelf`,
            async () => await rcSDK.getMyself().then(user => {
                return user;
            })
        );
    }
}

function createExtraReducers(builder) {
    return builder
        .addCase(`${name}/getMySelf/pending`, (state, action) => {
            state = {loading: true, profile: null, error: null}
            return state
        })
        .addCase(`${name}/getMySelf/fulfilled`, (state, action) => {
            state = {loading: false, profile: action.payload, error: null}
            return state
        })
        .addCase(`${name}/getMySelf/rejected`, (state, action) => {
            state = {loading: false, profile: null, error: action.error}
            return state
        })
}