import { configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger'

import { authReducer } from '../services/Auth/AuthSlice';
import { userReducer } from './UserSlice';
import { appReducer } from './AppSlice';

const DEBUG = ["development", "staging"].includes(process.env.NODE_ENV) ? true : false;
const loggerMiddleware = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error
})
const middlewares = [...getDefaultMiddleware(), DEBUG && loggerMiddleware].filter(Boolean);

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    user: userReducer,
  },
  middleware: [...middlewares]
});